<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('invoices') }} {{ $t('suppliers') }}
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="4"
        lg="3"
        class="d-flex align-items-end mb-1"
      >
        <b-form-group
          class="mr-1 mb-0 w-100"
          :label="$t('startDate')"
        >
          <b-form-datepicker
            v-model="orderFilters.startDate"
            reset-button
            @input="getOrdersWithFilters"
          />
        </b-form-group>
      </b-col>
      <b-col
        sm="4"
        lg="3"
        class="d-flex align-items-end mb-1"
      >
        <b-form-group
          class="mr-1 mb-0 w-100"
          :label="$t('endDate')"
        >
          <b-form-datepicker
            v-model="orderFilters.endDate"
            reset-button
            @input="getOrdersWithFilters"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="false"
        sm="4"
        lg="3"
        class="d-flex align-items-end mb-1"
      >
        <b-form-group
          class="mr-1 mb-0 w-100"
          :label="$t('supplier')"
        >
          <vue-bootstrap-typeahead
            v-model="supplierQuery"
            :placeholder="$t('search')"
            :data="[...suppliersResults]"
            :serializer="item => item.name"
            :show-all-results="true"
            :max-matches="5"
            @hit="selectCustomer($event)"
          >
            <template
              slot="suggestion"
              slot-scope="{ data }"
            >
              <div>
                <span style="font-size: 12px">{{ data.name }}</span>
              </div>
            </template>
          </vue-bootstrap-typeahead>
        </b-form-group>
      </b-col>
      <b-col
        v-if="false"
        sm="4"
        lg="3"
        class="d-flex align-items-end mb-1"
      >
        <b-form-group
          class="mr-1 mb-0 w-100"
          :label="$t('branch')"
        >
          <vue-bootstrap-typeahead
            v-model="branchQuery"
            :placeholder="$t('search')"
            :data="[...branchesResults]"
            :serializer="item => item.name"
            :show-all-results="true"
            :max-matches="5"
            @hit="selectCustomer($event)"
          >
            <template
              slot="suggestion"
              slot-scope="{ data }"
            >
              <div>
                <span style="font-size: 12px">{{ data.name }}</span>
              </div>
            </template>
          </vue-bootstrap-typeahead>
        </b-form-group>
      </b-col>
      <b-col
        sm="4"
        lg="3"
        class="d-flex align-items-end mb-1"
      >
        <b-overlay :show="downloadLoading">
          <b-button
            class=""
            variant="success"
            @click="download"
          >
            {{ $t('download') }}
          </b-button>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="loading">
          <supplier-invoices-table :invoices="purchaseOrders" />
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-pagination
          :value="purchaseOrdersPagination.page"
          :total-rows="purchaseOrdersPagination.total_objects"
          :per-page="purchaseOrdersPagination.per_page"
          aria-controls="my-table"
          @change="nextPage($event)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _ from 'underscore'
import SupplierInvoicesTable from '@/components/invoice/SupplierInvoicesTable.vue'

export default {
  components: {
    SupplierInvoicesTable,
  },

  data() {
    return {
      loading: false,
      invoices: [],
      downloadLoading: false,
      selectedCustomer: { id: null },
      newInvoice: {
        name: '',
        folio: '',
        file: null,
      },
      orderFilters: {
        startDate: null,
        endDate: null,
      },
      supplierQuery: '',
      suppliersResults: [],
      supplierSelected: {
        id: null,
      },
      branchQuery: '',
      branchesResults: [],
    }
  },

  computed: {
    ...mapGetters('purchaseOrders', [
      'purchaseOrders',
      'purchaseOrdersPagination',
    ]),
    ...mapGetters('projects', ['project']),
    ...mapGetters('suppliers', {
      files: 'newProviderFiles',
    }),
  },

  watch: {
    supplierQuery: _.debounce(function search(query) {
      if (query.length === 0) {
        this.loading = true
        this.fetchPurchaseOrders({ isInvoiced: true }).finally(() => {
          this.loading = false
        })
        return
      }
      this.fetchCustomers({
        byName: query,
        meta: {
          pagination: {
            per_page: 10,
          },
        },
      }).then(response => {
        this.customersResults = JSON.parse(JSON.stringify(response.data))
      })
      // this.loading = false
    }, 500),
  },

  created() {
    this.loading = true
    this.fetchPurchaseOrders({ isInvoiced: true }).finally(() => {
      this.loading = false
    })
  },

  destroyed() {},

  methods: {
    ...mapMutations('purchaseOrders', {
      changePagination: 'CHANGE_PAGINATION',
    }),
    ...mapActions('purchaseOrders', [
      'fetchPurchaseOrders',
      'downloadPurchaseOrders',
    ]),
    ...mapActions('customers', ['fetchCustomers']),

    selectCustomer(customer) {
      this.selectedCustomer.id = customer.id
      this.getOrdersWithFilters()
    },

    nextPage(value) {
      this.changePagination({ field: 'page', value })
      this.loading = true
      this.fetchPurchaseOrders({
        by_supplier_is_invoiced: true,
        invoicedAt: {
          start_date: this.orderFilters.startDate,
          end_date: this.orderFilters.endDate,
        },
        meta: { pagination: this.purchaseOrdersPagination },
      }).then(() => {
        this.loading = false
      })
    },
    download() {
      this.downloadLoading = true
      this.downloadPurchaseOrders({
        by_is_invoiced: true,
        by_invoiced_at: {
          start_date: this.orderFilters.startDate,
          end_date: this.orderFilters.endDate,
        },
      }).finally(() => {
        this.downloadLoading = false
      })
    },
    async getOrdersWithFilters() {
      this.loading = true
      await this.fetchPurchaseOrders({
        isInvoiced: true,
        invoicedAt: {
          start_date: this.orderFilters.startDate,
          end_date: this.orderFilters.endDate,
        },
        meta: { pagination: this.purchaseOrdersPagination },
      })
      this.loading = false
    },
  },
}
</script>

<style></style>
